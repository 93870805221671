import { model } from '@telekomconsalting/dex-guru-model'
import React, { FC } from 'react'

import { configCategoriesColor } from '../../config/settings'
import { CategoryIcon } from '../CategoryIcon/CategoryIcon'

interface CategoriesGroupProps {
  categories: (model.TraderCategoryName | model.LiquidityProviderCategoryName)[]
  tooltipOther: string
}

const CategoriesGroup: FC<CategoriesGroupProps> = ({ categories, tooltipOther }) => {
  return (
    <div className="categories-legend">
      {categories.map((item) => (
        <div
          className="categories-legend__item"
          key={item}
          style={
            {
              '--dexguru-color': `${configCategoriesColor[item]}`,
            } as React.CSSProperties
          }>
          <span className="icon">
            <CategoryIcon category={item} />
          </span>
        </div>
      ))}
      <div className="categories-legend__item" data-tip={tooltipOther} data-for="app-tooltip">
        Other
      </div>
    </div>
  )
}

export default CategoriesGroup
