import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../reducers'
import { getCurrencyName, pickValueBasedOnCurrency } from '../../utils'
import AnimatedNumber from '../AnimatedNumber'
import Delta from '../Delta'
import { Heading } from '../Heading'
import { calculateTopPartValue, getAnimatedColor } from '../Sidebar/helpers'
import {
  selectCurrentPoolActivityElement,
  selectCurrentTradingHistoryElement,
} from '../Sidebar/selectors'
import { SidebarType } from '../Sidebar/sidebarUtils'

interface ActivityOverviewHeaderProps {
  type: SidebarType
}

export const ActivityOverviewHeader: FC<ActivityOverviewHeaderProps> = (props) => {
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const currency = useSelector((state: State) => state.currency)
  const currentTradingHistoryElement = useSelector(selectCurrentTradingHistoryElement)
  const currentPoolActivityElement = useSelector(selectCurrentPoolActivityElement)

  const topPartValue = calculateTopPartValue(props.type, currency, currentToken)

  const [color, setColor] = useState<string>()

  useEffect(() => {
    const animatedColor = getAnimatedColor(
      currentTradingHistoryElement,
      currentPoolActivityElement,
      currentToken,
      props.type
    )
    setColor(animatedColor)
  }, [currentTradingHistoryElement, currentPoolActivityElement, currentToken, props.type])

  if (!currentToken) {
    return null
  }

  const currencyDisplayName = getCurrencyName(currentToken.network, currency)
  const volumeChange24h = pickValueBasedOnCurrency(
    currency,
    currentToken.volumeUSDChange24h,
    currentToken.volumeETHChange24h
  )
  const liquidityChange24h = pickValueBasedOnCurrency(
    currency,
    currentToken.liquidityUSDChange24h,
    currentToken.liquidityETHChange24h
  )

  return (
    <div className="activity-overview__header">
      <Heading size="xl" className="activity-overview__title">
        {props.type === 'liquidity' ? 'Token Liquidity' : 'Trading Volume, 24h'}
      </Heading>
      <div className="activity-overview__value">
        {currency === 'USD' && <span className="sign">$</span>}
        <AnimatedNumber className="value" value={topPartValue || 0} color={color} />
        {currency !== 'USD' && <span className="sign">&nbsp;{currencyDisplayName}</span>}
        {currentToken && (
          <Delta
            size="md"
            value={(props.type === 'liquidity' ? liquidityChange24h : volumeChange24h) * 100}
            tooltip="Over the last 24h"
          />
        )}
      </div>
    </div>
  )
}
