import classNames from 'classnames'
import React, { FC } from 'react'

import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { numberWithCommas } from '../../utils'
import IconAmm from '../TraderProfile/IconAmm'

interface TopPartAmmItemProps {
  caption: string
  color?: string
  logoURI: string
  active?: boolean
  value: number
  prefix?: string
  suffix?: string
  amm: string
  onClick: (amm: string) => void
}
const TopPartAmmItem: FC<TopPartAmmItemProps> = (props) => {
  const { caption, color, logoURI, active, value, prefix, suffix, amm, onClick } = props
  const title = `${prefix ? prefix : ''}${value ? numberWithCommas(value) : 0} ${
    suffix ? suffix : ''
  }`
  return (
    <div
      className={classNames('amm-switch-entry', { active: active })}
      title={title}
      style={
        {
          '--dexguru-amm-color': color,
        } as React.CSSProperties
      }
      onClick={(): void => onClick(amm)}>
      <div className="amm-switch-entry__header">
        <IconAmm
          logoURI={logoURI}
          displayName={caption}
          className="amm-switch-entry__icon"
          size="xs"
        />
        <div className="amm-switch-entry__title">{caption}</div>
      </div>
      <div className="amm-switch-entry__amount">
        {prefix && <span className="sign">{prefix}</span>}
        <span className="value">
          {financialFormat(value, {
            decimals: 2,
            roundBigNumbersFrom: 'K',
          })}
        </span>
        {suffix && <span className="sign">&nbsp;{suffix}</span>}
      </div>
    </div>
  )
}

export default TopPartAmmItem
