import { model } from '@telekomconsalting/dex-guru-model'
import { ChartData, InteractionItem } from 'chart.js'
import groupBy from 'lodash.groupby'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getAmmConfig } from '../../config/amm'
import {
  configCategoriesColor,
  defaultPeriodSidebarFullChart,
  SIDEBARS_GRAPHS_REFRESH_INTERVAL,
} from '../../config/settings'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { LocalToUTCEndOfDay, LocalToUTCStartOfDay } from '../../helpers/datetime'
import { AMMModel, ChartRowCategoryV3, ChartRowV3 } from '../../model'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import {
  loadChartsData,
  loadChartsDataByTraderCategory,
  loadLastAMMs,
} from '../../services/chartService'
import { getCurrencyName, getMarketDisplayName } from '../../utils'
import ButtonsGroup from '../ButtonsGroup'
import ChartDetailed from '../Chart/ChartDetailed'
import Loader from '../Loader'
import { ClickPoolAddressHandler } from '../PoolActivity/useTradeHistoryFilter'
import { SidebarType } from '../Sidebar/sidebarUtils'
import TopPools from '../TopPools'
import { ActivityOverviewHeader } from './ActivityOverviewHeader'
import AmmItem from './AmmItem'
import CategoriesGroup from './CategoriesGroup'

export enum ChartType {
  market = 'market',
  category = 'category',
}

type ChartDataByCategory = {
  [category: model.TraderCategoryName | string]: number[]
}

interface ActivityOverviewProps {
  type: SidebarType
  onLoadingChange: (isLoading: boolean) => void
  amm?: string | null
  onSelectAmm: (amm?: string | null) => void
  onClickPoolAddress: ClickPoolAddressHandler
  onSelectChartElement?: (timestampStart: number, timestampEnd: number) => void
}

const ActivityOverview: FC<ActivityOverviewProps> = (props) => {
  const currentToken = useSelector((state: State) => state.tokens.currentToken)

  const [date, setDate] = useState<number[]>([]) // dates in timestamp
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [periodChart, setPeriodChart] = useState<number>(defaultPeriodSidebarFullChart)

  const [chartDataByMarket, setChartDataByMarket] = useState<{
    [amm: string]: ChartRowV3[]
  }>({})
  const [chartDataByCategory, setChartDataByCategory] = useState<ChartDataByCategory>({})
  const [lastAmms, setLastAmms] = useState<AMMModel[]>([])
  const [currentChart, setCurrentChart] = useState<ChartType>(ChartType.market)

  const currency = useSelector((state: State) => state.currency)

  useEffect(() => {
    if (!currentToken?.id || !currency) {
      return
    }
    const getData = async (): Promise<void> => {
      if (currentChart === ChartType.market) {
        getDataByMarket()
      }
      if (currentChart === ChartType.category) {
        getDataByCategory()
      }
    }
    getData()
    const timer = setInterval(getData, SIDEBARS_GRAPHS_REFRESH_INTERVAL)
    return (): void => clearInterval(timer)
    // TODO:
    // https://app.shortcut.com/dexguru/story/21251
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken?.id, currency, periodChart, currentChart])

  const getDataByMarket = async (): Promise<void> => {
    if (!currentToken?.id || !currency) {
      return
    }
    setIsLoading(true)
    const chartDataByMarket = await loadChartsData(currency, currentToken?.id, periodChart)
    // for example groupedByMarket = {uniswap: Array(8), sushiswap: Array(8)}
    const groupedByMarket = groupBy<ChartRowV3>(chartDataByMarket, (x: ChartRowV3) => x.AMM)
    const date = chartDataByMarket
      .map((item: ChartRowV3) => item.date)
      .filter((x, i, a) => a.indexOf(x) === i)

    setChartDataByMarket(groupedByMarket)
    setDate(date)
    setIsLoading(false)
  }

  const getDataByCategory = async (): Promise<void> => {
    if (!currentToken?.id || !currency) {
      return
    }
    setIsLoading(true)

    const chartDataByCategory = await loadChartsDataByTraderCategory(
      currency,
      currentToken?.id,
      periodChart
    )
    const groupedByCategory = [...walletCategoryValues, 'noob'].map(
      (category: model.TraderCategoryName | string) => {
        return {
          [category]: chartDataByCategory.map((item: ChartRowCategoryV3) => {
            if (Object.keys(item).includes(category)) {
              return item[category as model.TraderCategoryName]?.volume
            }
            return null
          }),
        }
      }
    )
    const date = chartDataByCategory.map((item: ChartRowCategoryV3) => item.timestamp || 0)

    setChartDataByCategory(Object.assign({}, ...groupedByCategory))
    setDate(date)
    setIsLoading(false)
  }

  useEffect(() => {
    if (currentToken?.id && currency) {
      const getLastAMMs = async (): Promise<void> => {
        await loadLastAMMs(currentToken?.id).then((amms) => {
          setLastAmms(amms)
          props?.onLoadingChange(false)
        })
      }
      getLastAMMs()
      const timer = setInterval(getLastAMMs, SIDEBARS_GRAPHS_REFRESH_INTERVAL)
      return (): void => clearInterval(timer)
    }
    // TODO:
    // https://app.shortcut.com/dexguru/story/21251
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken?.id, currency, props.onLoadingChange])

  const propertyByChartRow = props.type === 'liquidity' ? 'totalLiquidity' : 'dailyVolume'

  const generateData = (chartData: ChartRowV3[]): number[] => {
    const maxLength = Math.max(...Object.values(chartDataByMarket).map((item) => item.length))
    const data = chartData.map((item: ChartRowV3) => item[propertyByChartRow])

    // this loop adds fake dots to the chart's beginning
    //  so their length becomes equal
    while (data.length < maxLength) {
      data.unshift(NaN)
    }
    return data
  }

  const walletCategoryValues =
    props.type === 'liquidity'
      ? Object.values(model.LiquidityProviderCategoryName)
      : Object.values(model.TraderCategoryName)

  const data = (params: {
    chartDataByMarket: {
      [amm: string]: ChartRowV3[]
    }
  }): ChartData => {
    return {
      labels: date,
      datasets: Object.keys(params.chartDataByMarket).map((amm) => {
        const config = getAmmConfig(amm)
        return {
          label: amm,
          backgroundColor: config.borderColor,
          borderColor: config.borderColor,
          borderWidth: 1,
          hoverBorderColor: '#fff',
          hoverBackgroundColor: config.borderColor,
          hitRadius: 15,
          tension: 0.4,
          fill: true,
          data: generateData(chartDataByMarket[amm]),
        }
      }),
    }
  }

  const dataByTrader = (chartDataGroupedByTrader: ChartDataByCategory): ChartData => {
    return {
      labels: date,
      datasets: Object.keys(chartDataGroupedByTrader).map(
        (category: model.TraderCategoryName | string) => {
          return {
            label: category,
            backgroundColor: configCategoriesColor[category],
            borderColor: configCategoriesColor[category],
            borderWidth: 1,
            hoverBorderColor: '#fff',
            hoverBackgroundColor: configCategoriesColor[category],
            hitRadius: 15,
            tension: 0.4,
            fill: true,
            data: chartDataGroupedByTrader[category],
          }
        }
      ),
    }
  }

  const chartData = React.useMemo(() => {
    if (currentChart === ChartType.category) {
      return dataByTrader(chartDataByCategory)
    }
    return data({ chartDataByMarket })
    // TODO:
    // https://app.shortcut.com/dexguru/story/21251
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataByMarket, chartDataByCategory, date])

  const propertyByAmm =
    props.type === 'liquidity'
      ? currency === 'USD'
        ? 'liquidityStable'
        : 'liquidityNative'
      : currency === 'USD'
      ? 'volumeStable'
      : 'volumeNative'

  if (!currentToken) {
    return null
  }

  const currencyDisplayName = getCurrencyName(currentToken.network, currency)

  const isShowLegend = (amm: string): boolean => {
    return !(chartDataByMarket[amm] || []).every((item) => !item[propertyByChartRow])
  }

  const isShowPool = currentToken?.marketType === 'token'

  const chartButtons: { title: string; value: ChartType }[] = [
    {
      title: 'By Market',
      value: ChartType.market,
    },
    {
      title: props.type === 'liquidity' ? 'By Liquidity Provider' : 'By Trader Category',
      value: ChartType.category,
    },
  ]

  const onChangeFilterChart = (value: ChartType): void => {
    amplitudeService.sendActivityFilterUpdateEvent(props.type, value)
    setCurrentChart(value)
  }

  const periodButtons = [
    {
      value: 1,
      title: '24h',
    },
    {
      value: defaultPeriodSidebarFullChart,
      title: '7 days',
    },
    {
      value: 30,
      title: '30 days',
    },
    {
      value: 90,
      title: 'All Time',
    },
  ]

  const onChangeChartPeriod = (period: string | number, title?: string): void => {
    setPeriodChart(parseInt(`${period}`))

    amplitudeService.sendEvent(
      props.type === SidebarType.liquidity
        ? AmplitudeEvent.CHANGED_TIME_PERIOD_ON_TOTAL_LIQUIDITY_CHART
        : AmplitudeEvent.CHANGED_TIME_PERIOD_ON_TRADING_VOLUME_CHART,
      {
        chart_timeperiod: title || period,
        token_id: currentToken?.id,
        token_name: getMarketDisplayName(currentToken),
        network: currentToken?.network,
      }
    )
  }

  const tooltipOptions = currentChart === ChartType.market ? { isAmm: true } : { isCategory: true }
  const tooltipOther =
    props.type === 'history'
      ? `Accounts with few on-chain transactions and small trading volumes that can't be classified as one of our categories.`
      : `Accounts that did not provide enough liquidity to be classified as one of our categories.`

  const handleSelectChartElement = (element: InteractionItem[]): void => {
    if (!props.onSelectChartElement) {
      return
    }

    const idx = element[0]?.index
    if (!idx && idx !== 0) {
      return
    }

    const timestamp = date[idx]

    const startTimestamp = LocalToUTCStartOfDay(new Date(1000 * timestamp))
    const endTimestamp = LocalToUTCEndOfDay(new Date(1000 * timestamp))

    if (startTimestamp && endTimestamp) {
      props.onSelectChartElement(Math.round(startTimestamp / 1000), Math.round(endTimestamp / 1000))
    }
  }

  return (
    <div className="activity-overview">
      <ActivityOverviewHeader type={props.type} />
      {props.type === 'history' && process.env.REACT_APP_CHART_BY_TRADER_CATEGORY === 'true' && (
        <div className="activity-overview__type">
          <ButtonsGroup
            buttons={chartButtons}
            value={currentChart}
            onChange={onChangeFilterChart}
          />
        </div>
      )}
      {currentChart === ChartType.market && (
        <div className="activity-overview__amms">
          <div className="amm-switch">
            <ul className="amm-switch__list">
              {lastAmms
                .filter((item) => isShowLegend(item.AMM) && item[propertyByAmm] !== 0)
                .sort((a: AMMModel, b: AMMModel) => b[propertyByAmm] - a[propertyByAmm])
                .map((item) => {
                  const amm = item.AMM
                  const ammConfig = getAmmConfig(amm)
                  return (
                    <li className="amm-switch__item" key={amm}>
                      <AmmItem
                        caption={amm === 'all' ? 'Others' : ammConfig.displayName}
                        logoURI={ammConfig.logoURI}
                        color={ammConfig.borderColor}
                        active={amm === props.amm}
                        value={item[propertyByAmm]}
                        prefix={currency === 'USD' ? '$' : undefined}
                        suffix={currency === 'ETH' ? currencyDisplayName : undefined}
                        amm={amm}
                        onClick={(): void => props.onSelectAmm(props.amm === amm ? null : amm)}
                      />
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      )}
      {currentChart === ChartType.category && props.type === 'history' && (
        <div className="activity-overview__categories">
          <CategoriesGroup categories={walletCategoryValues} tooltipOther={tooltipOther} />
        </div>
      )}
      <div className="activity-overview__chart">
        <div className="activity-overview-chart">
          <div className="activity-overview-chart__header">
            <ButtonsGroup
              buttons={periodButtons}
              value={defaultPeriodSidebarFullChart}
              onChange={onChangeChartPeriod}
            />
          </div>
          <div className="activity-overview-chart__body">
            {isLoading && <Loader modifier="sm" />}
            {!isLoading && (
              <ChartDetailed
                type="bar"
                stacked={true}
                logarithmic={false}
                data={chartData}
                tooltipOptions={{
                  ...tooltipOptions,
                  isSorted: true,
                  currency: currencyDisplayName,
                  skipEmpty: true,
                }}
                isTotal={true}
                period={periodChart === 1 ? 'day' : undefined}
                onClick={handleSelectChartElement}
              />
            )}
          </div>
        </div>
      </div>
      <div className="activity-overview__chart">
        {isShowPool && <TopPools onClickPoolAddress={props.onClickPoolAddress} />}
      </div>
    </div>
  )
}

export default ActivityOverview
